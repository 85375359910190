import { createReducer } from '@reduxjs/toolkit';
import * as types from './types';
import { FETCHING_STATUS } from '../../../../../../constants';
import applicationDetailsTypes from '../../../redux/constants';
import { SEARCH_TRIGGERS } from '../utils';

const initialState = {
  fetchingStatus: FETCHING_STATUS.INIT,
  products: [],
  serviceabilityInfos: {},
};

const productSearchReducer = createReducer(initialState, {
  [types.SEARCH_PRODUCTS_START]: (state, { searchCriteria, searchTrigger }) => ({
    ...state,
    fetchingStatus: FETCHING_STATUS.START,
    products: searchTrigger === SEARCH_TRIGGERS.CRITERIA_FORM ? [] : state.products,
    searchCriteria,
    serviceabilityInfos: {},
    searchTrigger,
  }),
  [types.SEARCH_PRODUCTS_SUCCESS]: (state, { payload, searchCriteria }) => ({
    ...state,
    fetchingStatus: FETCHING_STATUS.SUCCESS,
    products: payload,
    searchCriteria,
  }),
  [types.SEARCH_PRODUCTS_FAIL]: (state) => ({
    ...state,
    fetchingStatus: FETCHING_STATUS.ERROR,
    products: [],
    searchCriteria: null,
  }),
  [types.UPDATE_CUSTOM_PRODUCT]: (state, { payload }) => ({
    ...state,
    customProduct: payload,
  }),
  [types.REMOVE_CUSTOM_PRODUCT]: (state) => ({
    ...state,
    customProduct: null,
  }),
  [applicationDetailsTypes.SWITCH_APPLICATION]: () => ({
    ...initialState,
  }),
});

export default productSearchReducer;
