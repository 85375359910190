import find from 'lodash/find';
import isInteger from 'lodash/isInteger';
import omit from 'lodash/omit';
import { createReducer } from '../../../../../../utils/reducerGenerator';
import types from '../../constants';
import { formatCurrency } from '../../../../../../utils/formatters';
import * as productComparisonTypes from '../../../ApplicationDetailContentNav/ProductComparison/redux/types';

const initialState = {};

const getApplicationDetail = application => omit(application, [
  'applicationStatus', 'activityLogs', 'applicationUpdates', 'loans', 'taskLists']);

const updateFundingWorksheet = (state, action) => ({
  ...state,
  fundingWorksheet: {
    ...state.fundingWorksheet,
    ...action.payload,
  },
});

const updateSoma = (state, action) => ({
  ...state,
  somas: action.payload.somas,
  preSubmission: {
    ...state.preSubmission,
    ...action.payload.preSubmission,
  },
});

export const applicationDetails = createReducer(initialState, {
  [types.GET_APPLICATION_DETAIL_SUCCESS]: (state, action) => (getApplicationDetail(action.data)),
  [types.SYNC_APPLICANTS_INFO_SUCCESS]: (state, action) => (getApplicationDetail(action.data)),
  [types.UPDATE_APPLICATION_DETAIL_SUCCESS]: (state, action) => ({
    ...state,
    ...action.data,
  }),
  [types.UPDATE_SURVEY_CONTACT_SUCCESS]: (state, { applicantId }) => ({
    ...state,
    applicants: {
      ...state.applicants,
      persons: state.applicants.persons.map(applicant => ({
        ...applicant,
        isSurveyContact: applicant.id === applicantId,
      })),
    },
  }),
  [types.UPDATE_APPLICATION_GENERAL_INSURANCE_SUCCESS]: (state, action) => ({
    ...state,
    insuranceReferrals: {
      ...state.insuranceReferrals,
      allianzReferral: action.data,
    },
  }),
  [types.UPDATE_LOAN_PROTECTION_INSURANCE_SUCCESS]: (state, action) => ({
    ...state,
    insuranceReferrals: {
      ...state.insuranceReferrals,
      loanProtectionInsurance: action.data,
    },
  }),
  [types.UPDATE_EXPECTED_SETTLEMENT_DATE_SUCCESS]: (state, action) => ({
    ...state,
    expectedSettlementDate: action.data.expectedSettlementDate,
  }),
  [types.UPDATE_FUNDING_WORKSHEET]: updateFundingWorksheet,
  [types.UPDATE_SOMA]: updateSoma,
  [types.UPDATE_APPLICANTS]: (state, { payload }) => {
    const { applicants } = state;
    const newPersonalApplicants = payload.persons.map(person => {
      const toUpdateApplicant = find(applicants, { id: person.id });
      return toUpdateApplicant ? { ...toUpdateApplicant, ...person } : person;
    });

    return ({
      ...state,
      applicants: {
        ...applicants,
        persons: newPersonalApplicants,
      },
    });
  },
  [productComparisonTypes.UPDATE_PRODUCT_COMPARISON_SUCCESS]: (state, {
    data, applicationId, lender, totalLoanAmount, lenderBrokerCode, productComparisonId, isOwnerOccupied,
  }) => {
    if (applicationId !== state.id) {
      return state;
    }
    return {
      ...state,
      productComparison: { ...data, id: productComparisonId },
      lender,
      lenderBrokerCode,
      totalLoanAmount: formatCurrency(totalLoanAmount, { precision: isInteger(totalLoanAmount) ? 0 : 2 }),
      isOwnerOccupied,
    };
  },
  [productComparisonTypes.UPDATE_ESTIMATED_COMMISSION_LOAN_AMOUNT]: (state, {
    applicationId, estimatedCommissionLoanAmount,
  }) => {
    if (applicationId !== state.id) {
      return state;
    }
    return {
      ...state,
      preSubmission: {
        ...state.preSubmission,
        estimatedCommissionLoanAmount,
      },
    };
  },
  [types.UPDATE_APPLICATION_STATUS_SUCCESS]: (state, { referrer }) => ({
    ...state,
    referrer,
  }),
  [types.UPDATE_APPLICATION_ABSTRACT_SUCCESS]: (state, { applicationAbstract }) => ({
    ...state,
    applicationNumber: applicationAbstract.applicationNumber,
    writer: {
      id: applicationAbstract.adviserId,
      fullName: applicationAbstract.adviser,
    },
    adviser: applicationAbstract.adviser,
    expectedSettlementDate: applicationAbstract.expectedSettlementDate,
    preApprovalExpiryDate: applicationAbstract.preApprovalExpiryDate,
    financeDueDate: applicationAbstract.financeDueDate,
  }),
});

export default applicationDetails;
