export default {
  // application detail
  GET_APPLICATION_DETAIL_START: 'GET_APPLICATION_DETAIL_START',
  GET_APPLICATION_DETAIL_SUCCESS: 'GET_APPLICATION_DETAIL_SUCCESS',
  GET_APPLICATION_DETAIL_ERROR: 'GET_APPLICATION_DETAIL_ERROR',
  UPDATE_APPLICATION_DETAIL_SUCCESS: 'UPDATE_APPLICATION_DETAIL_SUCCESS',
  UPDATE_APPLICATION_ABSTRACT_SUCCESS: 'UPDATE_APPLICATION_ABSTRACT_SUCCESS',
  SWITCH_APPLICATION: 'APPLICATION_DETAIL:SWITCH_APPLICATION',

  // fact find detail
  SUBMIT_APPLICATION_SUCCESS: 'SUBMIT_APPLICATION_SUCCESS',

  // application loans
  GET_LOANS_START: 'GET_LOANS_START',
  GET_LOANS_SUCCESS: 'GET_LOANS_SUCCESS',
  UPDATE_APPLICATION_LOANS_SUCCESS: 'UPDATE_APPLICATION_LOANS_SUCCESS',

  // application status
  UPDATE_APPLICATION_STATUS_START: 'APPLICATION_DETAIL_UPDATE_APPLICATION_STATUS_START',
  UPDATE_APPLICATION_STATUS_SUCCESS: 'APPLICATION_DETAIL_UPDATE_APPLICATION_STATUS_SUCCESS',
  UPDATE_APPLICATION_STATUS_ERROR: 'APPLICATION_DETAIL_UPDATE_APPLICATION_STATUS_ERROR',

  // application expected settlement date
  UPDATE_EXPECTED_SETTLEMENT_DATE_SUCCESS: 'UPDATE_EXPECTED_SETTLEMENT_DATE_SUCCESS',

  // task list
  CREATE_TASK_START: 'CREATE_TASK_START',
  CREATE_TASK_SUCCESS: 'CREATE_TASK_SUCCESS',
  CREATE_TASK_ERROR: 'CREATE_TASK_ERROR',

  UPDATE_TASK_LISTS_START: 'UPDATE_TASK_LISTS_START',
  UPDATE_TASK_LISTS_SUCCESS: 'UPDATE_TASK_LISTS_SUCCESS',
  UPDATE_TASK_LISTS_ERROR: 'UPDATE_TASK_LISTS_ERROR',

  // task
  UPDATE_TASK_START: 'UPDATE_TASK_START',
  UPDATE_TASK_SUCCESS: 'UPDATE_TASK_SUCCESS',
  UPDATE_TASK_ERROR: 'UPDATE_TASK_ERROR',

  DELETE_TASK_START: 'DELETE_TASK_START',
  DELETE_TASK_SUCCESS: 'DELETE_TASK_SUCCESS',
  DELETE_TASK_ERROR: 'DELETE_TASK_ERROR',

  SELECT_TASK: 'SELECT_TASK',
  SET_TASK_DETAIL_VISIBLE: 'SET_TASK_DETAIL_VISIBLE',

  // Comment
  ADD_COMMENT_SUCCESS: 'ADD_COMMENT_SUCCESS',

  // application update
  ACKNOWLEDGE_UPDATE_SUCCESS: 'ACKNOWLEDGE_UPDATE_SUCCESS',
  ACKNOWLEDGE_UPDATE_START: 'ACKNOWLEDGE_UPDATE_START',
  ACKNOWLEDGE_UPDATE_ERROR: 'ACKNOWLEDGE_UPDATE_ERROR',
  UPDATE_APPLICATION_UPDATES: 'UPDATE_APPLICATION_UPDATES',

  // mark task as completed/uncompleted
  CHANGE_TASK_STATUS_SUCCESS: 'CHANGE_TASK_STATUS_SUCCESS',

  // activity logs
  UPDATE_ACTIVITY_LOGS: 'UPDATE_ACTIVITY_LOGS',

  // survey contact update
  UPDATE_SURVEY_CONTACT_SUCCESS: 'UPDATE_SURVEY_CONTACT_SUCCESS',

  // general insurance referral
  RETRIEVE_REFERRAL_ADVISERS_SUCCESS: 'RETRIEVE_REFERRAL_ADVISERS_SUCCESS',
  UPDATE_APPLICATION_GENERAL_INSURANCE_SUCCESS: 'UPDATE_APPLICATION_GENERAL_INSURANCE_SUCCESS',

  // loan protection insurance
  UPDATE_LOAN_PROTECTION_INSURANCE_START: 'UPDATE_LOAN_PROTECTION_INSURANCE_START',
  UPDATE_LOAN_PROTECTION_INSURANCE_SUCCESS: 'UPDATE_LOAN_PROTECTION_INSURANCE_SUCCESS',
  UPDATE_LOAN_PROTECTION_INSURANCE_ERROR: 'UPDATE_LOAN_PROTECTION_INSURANCE_ERROR',

  UPDATE_FUNDING_WORKSHEET: 'UPDATE_FUNDING_WORKSHEET',

  UPDATE_ESTIMATED_COMMISSION_LOAN_AMOUNT: 'UPDATE_ESTIMATED_COMMISSION_LOAN_AMOUNT',

  // applicant soma download document
  APPLICANT_SOMA_DOWNLOAD_START: 'APPLICANT_SOMA_DOWNLOAD_START',
  APPLICANT_SOMA_DOWNLOAD_SUCCESS: 'APPLICANT_SOMA_DOWNLOAD_SUCCESS',
  APPLICANT_SOMA_DOWNLOAD_ERROR: 'APPLICANT_SOMA_DOWNLOAD_ERROR',

  // guarantor soma download document
  GUARANTOR_SOMA_DOWNLOAD_START: 'GUARANTOR_SOMA_DOWNLOAD_START',
  GUARANTOR_SOMA_DOWNLOAD_SUCCESS: 'GUARANTOR_SOMA_DOWNLOAD_SUCCESS',
  GUARANTOR_SOMA_DOWNLOAD_ERROR: 'GUARANTOR_SOMA_DOWNLOAD_ERROR',

  UPDATE_APPLICANTS: 'UPDATE_APPLICANTS',

  // update product comparison
  UPDATE_PRODUCT_COMPARISON_SUCCESS: 'UPDATE_PRODUCT_COMPARISON_SUCCESS',
  UPDATE_PRODUCT_COMPARISON_START: 'UPDATE_PRODUCT_COMPARISON_START',
  UPDATE_PRODUCT_COMPARISON_ERROR: 'UPDATE_PRODUCT_COMPARISON_ERROR',

  // update somas
  UPDATE_SOMA: 'UPDATE_SOMA',

  // update employments
  UPDATE_EMPLOYMENTS_SUCCESS: 'UPDATE_EMPLOYMENTS_SUCCESS',
  UPDATE_EMPLOYMENTS_START: 'UPDATE_EMPLOYMENTS_START',

  // update applicants
  UPDATE_APPLICANTS_SUCCESS: 'UPDATE_APPLICANTS_SUCCESS',
  UPDATE_APPLICANTS_START: 'UPDATE_APPLICANTS_START',

  // update liabilities
  UPDATE_LIABILITIES_SUCCESS: 'UPDATE_LIABILITIES_SUCCESS',
  UPDATE_LIABILITIES_ERROR: 'UPDATE_LIABILITIES_ERROR',

  // sync applicants info
  SYNC_APPLICANTS_INFO_START: 'SYNC_APPLICANTS_INFO_START',
  SYNC_APPLICANTS_INFO_SUCCESS: 'SYNC_APPLICANTS_INFO_SUCCESS',
  SYNC_APPLICANTS_INFO_ERROR: 'SYNC_APPLICANTS_INFO_ERROR',

  // funding worksheet calculation
  FUNDING_WORKSHEET_FUNDING_POSITIONS_CALCULATION_START: 'FUNDING_WORKSHEET_FUNDING_POSITIONS_CALCULATION_START',
  FUNDING_WORKSHEET_FUNDING_POSITIONS_CALCULATION_SUCCESS: 'FUNDING_WORKSHEET_FUNDING_POSITIONS_CALCULATION_SUCCESS',
  FUNDING_WORKSHEET_FUNDING_POSITIONS_CALCULATION_ERROR: 'FUNDING_WORKSHEET_FUNDING_POSITIONS_CALCULATION_ERROR',

  FUNDING_WORKSHEET_FEES_CALCULATION_START: 'FUNDING_WORKSHEET_FEES_CALCULATION_START',
  FUNDING_WORKSHEET_FEES_CALCULATION_SUCCESS: 'FUNDING_WORKSHEET_FEES_CALCULATION_SUCCESS',
  FUNDING_WORKSHEET_FEES_CALCULATION_ERROR: 'FUNDING_WORKSHEET_FEES_CALCULATION_ERROR',

  // copy application
  COPY_APPLICATION_START: 'COPY_APPLICATION_START',
  COPY_APPLICATION_SUCCESS: 'COPY_APPLICATION_SUCCESS',
  COPY_APPLICATION_ERROR: 'COPY_APPLICATION_ERROR',

  // hidden application on swimlane
  UPDATE_APPLICATION_ON_SWIMLANE_STATUS: 'UPDATE_APPLICATION_ON_SWIMLANE_STATUS',

};

export const TRANSACTION_TYPES = {
  PURCHASING: 'Purchasing',
  OWNS: 'Owns',
};
