import isNumber from 'lodash/isNumber';
import get from 'lodash/get';
import classNames from 'classnames';
import React from 'react';
import {
  formatCostCalculation, formatOngoingFee,
  toDollarAmount, toPercentage,
  toRatePercentage, formatDiscountMonth,
} from 'shared/formatterUtils';
import withDefault from 'shared/components/Links/withDefault';
import ExternalLink from 'shared/components/Links/ExternalLink';
import isNil from 'lodash/isNil';
import VariantsCell from 'shared/components/productSearch/ComparisonTable/VariantsCell';
import styles from './styles.module.scss';
import TotalCostTermColumnHeader from './TotalCostTermColumnHeader';
import RepaymentFrequencyColumnHeader from './RepaymentFrequencyColumnHeader';

const toCompareResult = (direction) => (direction === 'asc' ? -1 : 1);

const CustomProductComparator = (
  { customProduct: aCustom, value: aValue },
  { customProduct: bCustom, value: bValue },
  direction,
) => {
  if (aCustom || bCustom) {
    return toCompareResult(direction);
  }

  if (isNil(aValue)) {
    return -1;
  }

  if (isNil(bValue)) {
    return 1;
  }

  if (aValue === bValue) {
    return 0;
  }

  return aValue > bValue ? 1 : -1;
};

const GetServiceabilityComparator = (serviceabilityInfosRef) => (
  aProduct, bProduct, direction,
) => {
  if (aProduct.customProduct || bProduct.customProduct) {
    return toCompareResult(direction);
  }

  const { current: serviceabilityInfos } = serviceabilityInfosRef;

  const aValue = get(serviceabilityInfos, [aProduct.id, 'data', 'maximumLoanAmount']);

  if (isNil(aValue)) {
    return direction === 'asc' ? 1 : -1;
  }

  const bValue = get(serviceabilityInfos, [bProduct.id, 'data', 'maximumLoanAmount']);

  if (isNil(bValue)) {
    return direction === 'asc' ? -1 : 1;
  }

  if (aValue === bValue) {
    return 0;
  }

  return aValue > bValue ? 1 : -1;
};

const formatProductNameCell = (value, variant, product, index) => (
  <div className={styles.productNameContainer}>
    {isNumber(index) && <span style={{ marginRight: '1em' }}>{index + 1}</span>}

    {product.customProduct === true
      ? <span style={{ margin: 0 }}>{`Custom: ${variant.displayName}`}</span>
      : withDefault(
        <ExternalLink to={variant.externalLink} style={{ verticalAlign: 'unset' }}>
          {variant.displayName}
        </ExternalLink>,
      )}
  </div>
);

const stickyColumnConfigs = [
  {
    name: 'lenderName',
    title: 'Lender',
    sticky: true,
    width: 180,
    allowSorting: true,
    align: undefined,
    classNames: {
      cell: styles.lenderNameCell,
      headerCell: styles.lenderNameHeaderCell,
    },
  },
  {
    name: 'externalLink',
    title: 'Product name',
    sticky: true,
    width: 220,
    align: undefined,
    classNames: {
      cell: styles.productNameCell,
    },
    renderer: (value, product) => (
      <VariantsCell
        product={product}
        format={formatProductNameCell}
      >
        <div className={styles.totalInfo}>
          Total:
        </div>
      </VariantsCell>
    ),
  },
  {
    name: 'totalCost',
    title: (
      <span>
        Total interest
        <br />
        and fees
      </span>
    ),
    sticky: true,
    allowSorting: true,
    renderer: (value, product) => (
      <VariantsCell
        product={product}
        format={(v, variant) => formatCostCalculation(variant.totalCost)}
      >
        <div className={styles.totalInfo}>
          {formatCostCalculation(product.totalCost)}
        </div>
      </VariantsCell>
    ),
  },
  {
    name: 'totalRepayment',
    title: (
      <span>
        Repayments
        <div className={styles.hint}>Incl. fees</div>
      </span>
    ),
    sticky: true,
    width: 140,
    allowSorting: true,
    renderer: (value, product) => (
      <VariantsCell
        product={product}
        format={(v, variant) => formatCostCalculation(variant.firstPeriodRepayment, variant.repaymentAvailable)}
      >
        <div className={styles.totalInfo}>
          {formatCostCalculation(product.totalRepayment)}
        </div>
      </VariantsCell>
    ),
  },
];

const scrollableColumnsConfigs = [
  {
    name: 'standardRate',
    title: (
      <span>
        Standard
        <br />
        rate
      </span>
    ),
    renderer: (value, product) => (
      <VariantsCell
        product={product}
        format={(v, variant) => toRatePercentage(variant.standardRate)}
      />
    ),
    align: 'right',
  },
  {
    name: 'discountRate',
    title: (
      <span>
        Introductory
        <br />
        interest rate
      </span>
    ),
    renderer: (value, product) => (
      <VariantsCell
        product={product}
        format={(v, variant) => toRatePercentage(variant.discountRate)}
      />
    ),
    align: 'right',
  },
  {
    name: 'discountMonths',
    title: (
      <span>
        Introductory
        <br />
        interest term
      </span>
    ),
    renderer: (value, product) => (
      <VariantsCell
        product={product}
        format={(v, variant) => (variant.discountMonths === 'N/A' ? variant.discountMonths : formatDiscountMonth(variant.discountMonths))}
      />
    ),
  },
  {
    name: 'upfrontFee',
    title: (
      <span>
        Upfront
        <br />
        fee
      </span>
    ),
    renderer: (value, product) => (
      <VariantsCell
        product={product}
        format={(v, variant) => toDollarAmount(variant.upfrontFee)}
      />
    ),
  },
  {
    name: 'ongoingFee',
    title: (
      <span>
        Ongoing
        <br />
        fee
      </span>
    ),
    renderer: (value, product) => (
      <VariantsCell
        product={product}
        format={(v, variant) => formatOngoingFee(variant.ongoingFee, variant.ongoingFeeFrequency)}
      />
    ),
  },
  {
    name: 'minLoanValueRatio',
    title: (
      <span>
        Minimum
        <br />
        LVR
      </span>
    ),
    renderer: (value, product) => (
      <VariantsCell
        product={product}
        format={(v, variant) => toPercentage(variant.minLoanValueRatio)}
      />
    ),
  },
  {
    name: 'maxLoanValueRatio',
    title: (
      <span>
        Maximum
        <br />
        LVR
      </span>
    ),
    renderer: (value, product) => (
      <VariantsCell
        product={product}
        format={(v, variant) => toPercentage(variant.maxLoanValueRatio)}
      />
    ),
  },
  {
    name: 'minLoanAmount',
    title: (
      <span>
        Minimum
        <br />
        loan amount
      </span>
    ),
    align: 'right',
    width: 140,
    renderer: (value, product) => (
      <VariantsCell
        product={product}
        format={(v, variant) => toDollarAmount(variant.minLoanAmount)}
      />
    ),
  },
  {
    name: 'maxLoanAmount',
    title: (
      <span>
        Maximum
        <br />
        loan amount
      </span>
    ),
    width: 140,
    renderer: (value, product) => (
      <VariantsCell
        product={product}
        format={(v, variant) => toDollarAmount(variant.maxLoanAmount)}
      />
    ),
  },
];

const serviceabilityColumnConfigs = [
  {
    name: 'serviceability',
    title: (
      <span>
        Pass
        <br />
        / Fail
      </span>
    ),
    width: 100,
    renderer: () => (<div />),
    align: undefined,
  },
  {
    name: 'maxBorrowingCapacity',
    title: (
      <span>
        Max
        <br />
        borrowing
        <br />
        capacity
      </span>
    ),
    width: 140,
    renderer: () => (<div />),
    align: undefined,
  },
];

const defaultColumnSetting = {
  wordWrapEnabled: true,
  align: 'right',
  width: 120,
};

const baseColumns = [
  ...stickyColumnConfigs,
  ...serviceabilityColumnConfigs,
  ...scrollableColumnsConfigs,
].map(col => ({
  ...defaultColumnSetting,
  ...col,
}));

const calculateColumns = ({
                            anySplitted,
                            totalCostSortable,
                            totalRepaymentSortable,
                            maximumBorrowingCapacitySortable,
                            clickLenderNameHandlerCreator,
                            onSelectTotalCostTerm,
                            onSelectRepaymentFrequency,
                            serviceabilityInfosRef,
                          }) => baseColumns.map((col) => {
    if (col.name === 'lenderName') {
      return {
        ...col,
        // eslint-disable-next-line react/prop-types
        renderer: ({ value }, product) => (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus
          <span
            role="button"
            onClick={clickLenderNameHandlerCreator(product)}
          >
            {value}
          </span>
        ),
        sorter: CustomProductComparator,
        getCellValue: ({ customProduct = false, lenderName }) => ({ customProduct, value: lenderName }),
      };
    }

    if (col.name === 'totalCost') {
      return {
        ...col,
        width: totalCostSortable ? 160 : 140,
        allowSorting: totalCostSortable,
        sorter: CustomProductComparator,
        getCellValue: ({ customProduct = false, totalCost }) => ({ customProduct, value: totalCost }),
        headerRenderer: (children, { allowSorting }) => (
          <div
            className={classNames({
              [styles.headerContainer]: true,
              [styles.sortingHeaderContainer]: allowSorting,
            })}
          >
            {children}
            <TotalCostTermColumnHeader onSelectTotalCostTerm={onSelectTotalCostTerm} />
          </div>
        ),
      };
    }

    if (col.name === 'totalRepayment') {
      return {
        ...col,
        allowSorting: totalRepaymentSortable,
        sorter: CustomProductComparator,
        getCellValue: ({ customProduct = false, totalRepayment }) =>
          ({ customProduct, value: totalRepayment }),
        headerRenderer: (children, { allowSorting }) => (
          <div
            className={classNames({
              [styles.headerContainer]: true,
              [styles.sortingHeaderContainer]: allowSorting,
            })}
          >
            {children}
            <RepaymentFrequencyColumnHeader onSelectRepaymentFrequency={onSelectRepaymentFrequency} />
          </div>
        ),
      };
    }

    if (col.name === 'maxBorrowingCapacity') {
      return {
        ...col,
        allowSorting: maximumBorrowingCapacitySortable,
        sorter: GetServiceabilityComparator(serviceabilityInfosRef),
        getCellValue: ({ id, customProduct = false }) => ({ customProduct, id }),
      };
    }

    if (col.name === 'standardRate') {
      return {
        ...col,
        allowSorting: !anySplitted,
        sorter: CustomProductComparator,
        getCellValue: ({ customProduct = false, variants: [{ standardRate }] }) =>
          ({ customProduct, value: standardRate }),
      };
    }

    if (col.name === 'discountRate') {
      return {
        ...col,
        allowSorting: !anySplitted,
        sorter: CustomProductComparator,
        getCellValue: ({ customProduct = false, variants: [{ discountRate }] }) =>
          ({ customProduct, value: discountRate }),
      };
    }
    return col;
  });

export default calculateColumns;
