import React from 'react';
import PropTypes from 'prop-types';
import InformationSection from 'shared/components/InformationSection/InformationSection';
import { Field } from 'redux-form';
import ReduxFormTextArea from 'shared/components/formFields/ReduxForm/TextArea';
import styles from './LenderComments.module.scss';
import { getMaxLengthValidator } from '../../shared/utils';

const validateMaxLength = getMaxLengthValidator(40000);

const LenderComments = ({ hasEditPermission }) => (
  <InformationSection
    title="Lender comments"
  >
    <div className={styles.lenderCommentsWrapper}>
      <Field
        name="preSubmission.comment"
        component={ReduxFormTextArea}
        placeholder="Type lender comments here."
        rows="15"
        disabled={!hasEditPermission}
        className={styles.textAreaWrapper}
        validate={validateMaxLength}
      />
    </div>
  </InformationSection>
);

LenderComments.propTypes = {
  hasEditPermission: PropTypes.bool.isRequired,
};

export default LenderComments;
