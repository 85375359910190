import React, {
  useCallback, useContext,
} from 'react';
import max from 'lodash/max';
import map from 'lodash/map';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { iSet } from 'shared/utils';
import { ConfirmContext } from 'shared/components/ConfirmProvider';
import SearchCriteria from './SearchCriteria/SearchCriteria';
import ProductsTable from './ProductsTable';
import { getApplicationId } from '../../redux/selectors';
import { getAllProducts } from './redux/products/products';
import { DEFAULT_REPAYMENT_FREQUENCY } from '../../../../../constants';
import { compareProducts } from '../ProductComparison/redux';
import { updateCustomProduct, removeCustomProduct } from './redux/customProduct/customProduct';
import { formatSearchCriteria, SEARCH_TRIGGERS } from './utils';

const profileSelector = state => state.profile;
const productSearchCriteriaSelector = state => get(state, 'application.productSearch.searchCriteria');
const customProductSelector = state => get(state, 'application.productSearch.customProduct');

const confirmationProps = {
  primaryButtonText: 'OK',
  secondaryButtonText: null,
  title: 'Serviceability unsupported',
  message: (
    <div>
      <p>{'Launchpad currently doesn\'t show serviceability results for applications.'}</p>
      <p>Please use lender calculations directly.</p>
    </div>
  ),
};

const ProductSearch = () => {
  const confirm = useContext(ConfirmContext);
  const dispatch = useDispatch();
  const { states } = useSelector(profileSelector);
  const applicationId = useSelector(getApplicationId);
  const customProduct = useSelector(customProductSelector);

  const searchAllProducts = useCallback(async ({
                                                 searchCriteria,
                                                 rawSearchCriteria,
                                                 clearCustomProduct,
                                                 searchTrigger,
                                               }) => {
    const products = await dispatch(getAllProducts(searchCriteria, rawSearchCriteria, searchTrigger));

    if (isEmpty(products)) {
      return;
    }

    const {
      loansCriteria,
      totalCostTerm,
      repaymentFrequency,
    } = rawSearchCriteria;
    if (!clearCustomProduct && !isNil(customProduct)) {
      await dispatch(updateCustomProduct(customProduct, loansCriteria, totalCostTerm, repaymentFrequency));
    }
    confirm(confirmationProps);
  }, [dispatch, customProduct, confirm]);

  const handleSearch = useCallback(async (rawSearchCriteria) => {
    const formattedSearchCriteria = formatSearchCriteria(rawSearchCriteria, states);
    const { loansCriteria } = rawSearchCriteria;
    const totalCostTerm = max(map(loansCriteria, 'loanTerm'));
    const repaymentFrequency = DEFAULT_REPAYMENT_FREQUENCY;
    const searchCriteria = map(formattedSearchCriteria, it => ({
      ...it,
      totalCostTerm,
      repaymentFrequency,
    }));
    dispatch(removeCustomProduct());
    return searchAllProducts({
      searchCriteria,
      rawSearchCriteria: {
        ...rawSearchCriteria,
        totalCostTerm,
        repaymentFrequency,
      },
      clearCustomProduct: true,
      searchTrigger: SEARCH_TRIGGERS.CRITERIA_FORM,
    });
  }, [searchAllProducts, states, dispatch]);

  const currentUsedSearchCriteria = useSelector(productSearchCriteriaSelector);

  const handleSelectTotalCostTerm = useCallback((totalCostTerm) => {
    const formattedSearchCriteria = formatSearchCriteria(currentUsedSearchCriteria, states);
    const searchCriteria = map(formattedSearchCriteria, it => ({
      ...it,
      totalCostTerm,
      repaymentFrequency: get(currentUsedSearchCriteria, 'repaymentFrequency'),
    }));
    const updatedSearchCriteria = iSet(currentUsedSearchCriteria, 'totalCostTerm', totalCostTerm);
    return searchAllProducts({
      searchCriteria,
      rawSearchCriteria: updatedSearchCriteria,
      clearCustomProduct: false,
      searchTrigger: SEARCH_TRIGGERS.HEADER_FILTER,
    });
  }, [searchAllProducts, states, currentUsedSearchCriteria]);

  const handleSelectRepaymentFrequency = useCallback((repaymentFrequency) => {
    const formattedSearchCriteria = formatSearchCriteria(currentUsedSearchCriteria, states);
    const searchCriteria = map(formattedSearchCriteria, it => ({
      ...it,
      repaymentFrequency,
      totalCostTerm: get(currentUsedSearchCriteria, 'totalCostTerm'),
    }));
    const updatedSearchCriteria = iSet(currentUsedSearchCriteria, 'repaymentFrequency', repaymentFrequency);
    return searchAllProducts({
      searchCriteria,
      rawSearchCriteria: updatedSearchCriteria,
      clearCustomProduct: false,
      searchTrigger: SEARCH_TRIGGERS.HEADER_FILTER,
    });
  }, [searchAllProducts, states, currentUsedSearchCriteria]);

  const history = useHistory();
  const handleCompare = useCallback((products) => {
    dispatch(compareProducts({
      products,
      searchCriteria: currentUsedSearchCriteria,
    }));
    history.push(`/applications/${applicationId}/productComparison`);
  }, [dispatch, currentUsedSearchCriteria, history, applicationId]);

  return (
    <>
      <SearchCriteria onSearch={handleSearch} />
      <ProductsTable
        onStartCompare={handleCompare}
        onSelectTotalCostTerm={handleSelectTotalCostTerm}
        onSelectRepaymentFrequency={handleSelectRepaymentFrequency}
      />
    </>
  );
};

export default ProductSearch;
